<template>
  <div class="c-time">
    <ul
      v-if="timeInfo && timeInfo.timeLists && timeInfo.timeLists.length"
      class="time-list"
    >
      <template v-for="(timeItem, timeIndex) in timeInfo.timeLists">
        <li
          v-if="timeItem.time !== '-'"
          :key="timeIndex"
        >
          <div class="date">
            {{ timeItem.date }}
          </div>
          <div class="time">
            <p>{{ transformTime(timeItem.time) }}</p>
          </div>
        </li>
      </template> 
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AddressTimeVue',
  filters: {
    filterTime (val) {
      return val.replace(/(\d{2})(\d{2})/, '$1:$2')
    }
  },
  props: {
    timeInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      closeTime: '00:00-00:00'
    }
  },
  methods: {
    transformTime(time) {
      if(time == this.closeTime)return this.language.SHEIN_KEY_PWA_26685
      return time
    },

  }
}
</script>
