export const saObj = {
  exposePopupPickupaddressEdit(data) {
    sa('send', {
      activity_name: 'expose_popup_pickupaddress_edit',
      activity_param: {
        ...data
      }
    })
  },
  clickPickUpAddressSearch() {
    sa('send', { activity_name: 'click_pickupaddress_search' })
  },
  saveStoreAddress: function (result) {
    sa('send', {
      activity_name: 'click_savepickupaddress',
      activity_param: {
        ...result
      }
    })
  },
  clickLockerPicture() {
    sa('send', {
      activity_name: 'click_locker_picture',
      activity_param: {}
    })
  },
  clickStoreConfirm({ store_type }) {
    sa('send', {
      activity_name: 'click_store_confirm',
      activity_param: {
        store_type
      }
    })
  },
  clickSavePostNumber({ result }) {
    sa('send', {
      activity_name: 'click_save_postnumber',
      activity_param: {
        result
      }
    })
  },
  clickSelectStore({ postnumber, store_type }) {
    sa('send', {
      activity_name: 'click_select_store',
      activity_param: {
        postnumber,
        store_type
      }
    })
  },
  clickPostnumberConfirm({ postnumber }) {
    sa('send', {
      activity_name: 'click_postnumber_confirm',
      activity_param: {
        postnumber
      }
    })
  },
  clickLogisticsSelect({ selected_storetype }) {
    sa('send', {
      activity_name: 'click_logistics_select',
      activity_param: {
        selected_storetype
      }
    })
  },
  exposeStoreSuggestion({ store_sort }) {
    sa('send', {
      activity_name: 'expose_store_suggestion',
      activity_param: {
        store_sort
      }
    })
  },
  clickListSelectpickupaddress({ sort, store_select }) {
    sa('send', {
      activity_name: 'click_list_selectpickupaddress',
      activity_param: {
        sort,
        store_select
      }
    })
  },
  clickSelectpickupaddressMapConfirm({ sort, store_select }) {
    sa('send', {
      activity_name: 'click_selectpickupaddress_map_confirm',
      activity_param: {
        sort,
        store_select
      }
    })
  }
}
