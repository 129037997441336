let tablist = {}
let tabs = {}
let tabitems = {}
export const configTrival = (name) => {
  tablist[name] = document.querySelectorAll(`.j-ada-${name}-tab`)[0]
  tabs[name] = document.querySelectorAll(`.j-ada-${name}-tabitem`)
  tabitems[name] = $(`.j-ada-${name}-tabitem`)
  // const panels = document.querySelectorAll(`.j-ada-${name}-tabpanel`)

  // console.log('111111111111', name, tablist, tabs, tabitems)

  // Add a click event handler to each tab
  tabs[name].forEach(tab => {
    tab.addEventListener('click', changeTabs)
  })

  // Enable arrow navigation between tabs in the tab list
  let tabFocus = 0

  tabitems[name].filter(item => {
    if (tabitems[name][item].getAttribute('aria-selected') == 'true'){
      tabFocus = item
    }
  })

  // console.log('2222222222222', tabFocus)

  tablist[name].addEventListener('keydown', e => {
    // Move right
    // left 37, rigth 39
    if (e.keyCode === 39 || e.keyCode === 37) {
      tabs[name][tabFocus].setAttribute('tabindex', -1)
      if (e.keyCode === 39) {
        tabFocus++
        // If we're at the end, go to the start
        if (tabFocus >= tabs[name].length) {
          tabFocus = 0
        }
        // Move left
      } else if (e.keyCode === 37) {
        tabFocus--
        // If we're at the start, move to the end
        if (tabFocus < 0) {
          tabFocus = tabs[name].length - 1
        }
      }

      tabs[name][tabFocus].setAttribute('tabindex', 0)
      tabs[name][tabFocus].focus()
    }
  })

  function changeTabs(e) {
    // console.log(e, '------------')
    const target = e.target
    const parent = target.parentNode
    // const grandparent = parent.parentNode;
    // console.log('------------- target -------', target)
    // console.log('------------ parent --------', parent)
    // console.log('------------ grandparent --------- ', grandparent)

    // Remove all current selected tabs
    parent
      .querySelectorAll('[aria-selected="true"]')
      .forEach(t => t.setAttribute('aria-selected', false))

    // Set this tab as selected
    target.setAttribute('aria-selected', true)

    // Hide all tab panels
    // grandparent
    //   .querySelectorAll('[role="tabpanel"]')
    //   .forEach(p => p.setAttribute("hidden", true));

    // Show the selected panel
    // grandparent.parentNode
    //   .querySelector(`#${target.getAttribute("aria-controls")}`)
    //   .removeAttribute("hidden");
  }
}
