<template>
  <div class="j-goods-detail-address-upper c-address-upper-store ru">
    <div
      ref="upperWrap"
      class="c-upper-wrap"
    >
      <header
        ref="upperHeader"
        class="common-address-header"
      >
        <section class="address-header-inner">
          <p
            ref="upperTitle"
            class="header-title"
            tabindex="0"
            role="heading"
          >
            {{ language.SHEIN_KEY_PWA_16223 }}
          </p>
          <span
            v-enterkey
            class="header-close"
            tabindex="0"
            role="button"
            :aria-label="language.SHEIN_KEY_PWA_15567"
            @click="clickCancel"
          > <i class="iconfont icon-close"></i> </span>
        </section>
        <section class="address-header-tab">
          <div
            class="tab-wrapper j-ada-cascader-tab"
            role="tablist"
            :aria-activedescendant="`ada-cascader-tab-${type}`"
          >
            <template v-for="option in options">
              <span
                v-if="option.isShow"
                :id="`ada-cascader-tab-${option.id}`"
                :key="option.id"
                v-enterkey
                class="j-tab-item j-ada-cascader-tabitem"
                :tabindex="type == option.id ? 0 : -1"
                role="tab"
                :aria-selected="type == option.id"
                :aria-labelledby="`ada-cascader-label-${option.id}`"
                :aria-controls="`ada-cascader-list-${option.id}`"
                :class="{ 'has-value': option.value, selected: type == option.id }"
                @click="tabNavChange(option.id)"
              >
                {{ option.value || language[option.placeholder] }}
              </span>
            </template>
          </div>
        </section>
        <section class="address-header-search">
          <div class="search-input">
            <i class="iconfont icon-search01"></i>
            <input
              ref="searchInput"
              v-model="searchValue"
              type="text"
              :placeholder="language[options[type].search]"
              @focus="searchFocus = true"
              @blur="searchBlur"
            />
            <i
              v-if="searchValue && searchFocus"
              class="iconfont icon-clear1"
              @click.stop.prevent="clearSearchInput"
            >
            </i>
          </div>
        </section>
      </header>

      <div
        v-if="type === 'store' || dataList.length"
        ref="upperList"
        class="c-upper-list has-search"
      >
        <div
          v-if="type === 'store'"
          class="addr-upper-item addr-area-item"
        >
          <ul
            v-if="dataList.length"
            class="upper-list"
          >
            <li
              v-for="(item2, index2) in dataList"
              :key="index2"
              :ref="'j-item-' + item2[valueKey]"
              v-enterkey
              class="result-item"
              DA-type="syncClick"
              tabindex="0"
              @click="selectItem(index2)"
            >
              <s-radio 
                :value="parseInt(selectedIndex)" 
                margin-r="0" 
                :label="index2" 
              />
              <div class="result-content">
                <p class="title flex">
                  <span class="name">{{ item2.storeName }}</span>
                  <span
                    v-if="item2.distance" 
                    class="distance"   
                  >
                    {{ transformDistance(item2) }}
                  </span>
                </p>
                <div
                  v-if="getLocationFields({ info: item2, type: 'logo' }) || getLocationFields({ info: item2, type: 'label' })"
                  class="logistics" 
                >
                  <div
                    v-if="getLocationFields({ info: item2, type: 'logo' })"
                    class="logo-panel"
                  >
                    <img
                      class="logo"
                      :src="getLocationFields({ info: item2, type: 'logo' })"
                      alt="logo"
                    />
                  </div>
                  <span 
                    v-if="getLocationFields({ info: item2, type: 'label' })"
                    class="type"
                  >
                    {{ language[getLocationFields({ info: item2, type: 'label' })] }}
                  </span>
                  <div
                    v-if="item2.isRecommend"
                    class="recommend"
                  >
                    <i class="suiiconfont sui_icon_time_15px"></i>
                    <span class="txt">{{ language.SHEIN_KEY_PWA_24653 }}</span>
                  </div>
                </div>
                <p class="desc flex">
                  <i class="suiiconfont sui_icon_location_15px"></i>
                  <span class="info">{{ item2.address }}</span>
                </p>
                <p class="open-time tel flex">
                  <i class="suiiconfont sui_icon_phone_15px_2"></i>
                  <span class="info">{{ item2.tel }}</span>
                </p>
                <p
                  v-if="item2 && item2.timeLists && item2.timeLists.length"
                  class="open-time flex"
                  @click.stop="clickChildStatus(item2)"
                >
                  <i class="suiiconfont sui_icon_time_15px"></i>
                  <span class="info">
                    {{ language.SHEIN_KEY_PWA_16633 }}
                    <i
                      class="iconfont"
                      :class="item2.childShow ? 'icon-shang' : 'icon-down'"
                    ></i>
                  </span>
                </p>
                <div
                  v-show="item2.childShow"
                  class="child"
                >
                  <time-vue
                    :time-info="item2"
                    :language="language"
                  />
                </div>
              </div>
            </li>
          </ul>
          <div
            v-else
            class="oops"
            tabindex="0"
          >
            <i class="iconfont icon-Rectangle"></i>
            <p class="desc">
              {{ language.SHEIN_KEY_PWA_16615 }}
            </p>
          </div>
        </div>
        <template v-else>
          <div
            v-for="(item1, index1) in shortcutList"
            :key="index1"
            :ref="'j-letter-' + index1"
            class="addr-upper-item addr-area-item"
            :class="['j-letter-' + index1]"
          >
            <ul class="upper-list">
              <template v-for="(item2, index2) in dataList">
                <li
                  v-if="item2 && item2[valueKey] && item1 == item2[valueKey].substr(0, 1)"
                  :key="index2"
                  :ref="'j-item-' + item2[valueKey]"
                  v-enterkey
                  class="upper-item"
                  :class="{ selected: item2[valueKey] == checkedValue, 'j-item-selected': item2[valueKey] == checkedValue }"
                  tabindex="0"
                  :aria-selected="item2[valueKey] == checkedValue"
                  @click="selectTarget(item2)"
                >
                  <span class="value">{{ item2[valueKey] }}</span>
                  <i
                    v-if="item2[valueKey] == checkedValue"
                    class="iconfont icon-normal-checked"
                  ></i>
                </li>
              </template>
            </ul>
          </div>
        </template>
      </div>
      <div class="bottom">
        <button
          class="mshe-btn-black mshe-btn-block j-adr-submit-btn"
          :disabled="selectedIndex === ''"
          DA-type="syncClick"
          DA-sa-name="pickupaddress_confirm"
          @click="clickSelected"
        >
          {{ language.SHEIN_KEY_PWA_15150 }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { saObj } from '../analysis'
import timeVue from '../vue_tpls/time'
import { configTrival } from 'public/src/pages/common/ada/a11y'
import { RU_STORE_LOCATION_TYPE, RU_STORE_LAST_LOGISTICS } from '../config'
import { Radio as SRadio } from '@shein/sui-mobile'

export default {
  name: 'AddressCascaderStoreRu',
  components: {
    timeVue,
    SRadio
  },
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    },
    isShortcut: {
      type: Boolean,
      default: true
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    pageName: {
      type: String,
      default: ''
    },
    abtTestConfig: {
      type: Object,
      default() {
        return {
          ShowType: 'off',
          ShowLogo: 'off'
        }
      }
    },
    addressStoreConfig: {
      type: Object,
      default() {
        return {
          store_config: {
            distance: 0,
            sort_type: 0
          },
          abtTestConfig: {
            search_home: 'off',
            google: 'off',
            ShowLogo: 'off',
            distance: 'off',
            pic: 'off',
            ShowType: 'off',
            logicts: 'off',
            channel: [],
          },
          store_info_list: []
        }
      }
    },
    sortType: {
      type: [Number, String],
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemIndex: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      RU_STORE_LOCATION_TYPE,
      RU_STORE_LAST_LOGISTICS,
      searchValue: '',
      searchFocus: false,
      selectedIndex: ''
    }
  },
  computed: {
    valueKey () {
      return this.type === 'store' ? 'storeName' : 'value'
    },
    dataList () {
      return this.type && this.options[this.type].subs || []
    },
    shortcutList () {
      const array = []
      this.dataList.map(item => {
        const first = item[this.valueKey].substr(0, 1)
        array.indexOf(first) == -1 && array.push(first)
      })
      return array
    },
    checkedValue () {
      return this.type && this.options[this.type].value || ''
    },
    isShowType () {
      return this.abtTestConfig.ShowType === 'on'
    },
    isShowLogo () {
      return this.abtTestConfig.ShowLogo === 'on'
    },
  },
  watch: {
    searchValue (value) {
      if (this.type === 'store') {
        return
      }
      this.scrollToItem(value)
    },
    checkedValue (value) {
      this.$nextTick(() => {
        this.scrollToItem(value)
      })
    },
    options: {
      handler() {
        this.$nextTick(() => {
          configTrival('cascader')
        })
      },
      deep: true
    },
    type() {
      this.$nextTick(() => {
        $(`#ada-cascader-tab-${ this.type }`).focus()
      })
    },
    show: {
      handler (v) {
        if (v) {
          this.searchValue = ''
          this.selectedIndex = this.itemIndex
        }
      },
      immediate: true
    }
  },
  methods: {
    getLocationFields ({ info = {}, type = 'logo' }) {
      if (type === 'logo') {
        const { last_logistics } = info
        if (!this.isShowLogo || !last_logistics) {
          return ''
        }
        const storeItemLogo =  this.addressStoreConfig?.store_info_list.find(item => item.store_channel_type == last_logistics)?.logo
        return storeItemLogo ? storeItemLogo :  RU_STORE_LAST_LOGISTICS[last_logistics] || ''
      }
      const { location_type } = info
      if (!this.isShowType || !location_type) {
        return ''
      } 
      return RU_STORE_LOCATION_TYPE[location_type]?.value || ''
    },
    clickSelected () {
      this.$emit('confirm')
    },
    getSortPosition({ index } = {}) {
      if (this.sortType === 0) {
        return 0
      }
      const { isRecommend } = this.dataList[index] || {}
      const position = isRecommend ? (index == 0 ? 1 : 2) : 3
      return position
    },
    selectItem (val) {
      this.selectedIndex = val
      saObj.clickListSelectpickupaddress({
        sort: this.sortType,
        store_select: this.getSortPosition({ index: this.selectedIndex }),
      })
      this.dataList.forEach((item, index) => {
        this.$set(item, 'childShow', val === index)
      })
      this.$emit('select', { type: 'store', item: this.dataList[this.selectedIndex], value: this.dataList[this.selectedIndex].storeName })
    },
    clickChildStatus (item) {
      this.$set(item, 'childShow', !item.childShow)
    },
    scrollToItem (value) {
      if (!value) return
      for (let i = 0; i < this.dataList.length; i++) {
        const listItem = this.dataList[i][this.valueKey]
        if (listItem.substr(0, value.length).toUpperCase() == value.toUpperCase()) {
          this.$refs.upperList.scrollTop = this.$refs['j-item-' + listItem][0].offsetTop - this.$refs.upperHeader.clientHeight
          break
        }
      }
    },
    clickCancel () {
      this.$emit('cancel')
    },
    selectTarget (item) {
      this.searchValue = ''
      if (this.type !== 'store') {
        this.selectedIndex = ''
      }
      this.$emit('select', { type: this.type, value: item[this.valueKey], item })
    },
    tabNavChange (type) {
      this.searchValue = ''
      this.$emit('change', type)
    },
    searchBlur () {
      setTimeout(() => {
        if (this.type === 'store') {
          saObj.clickPickUpAddressSearch()
          this.$emit('search', { keywords: this.searchValue })
        }
        this.searchFocus = false
      }, 20)
    },
    clearSearchInput () {
      this.searchValue = ''
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 30)
    },
    transformDistance(item = {}) {
      const { distance, isRecommend } = item
      if (!distance) {
        return ''
      }
      if (this.countryId == 225) {
        const miles = (distance * 0.00062137119223733).toFixed(2)
        return `${ item.isRecommend ? '< ' : '' }${ miles < 0.01 ? 0.01 : miles }miles` 
      }
      if (distance.length > 3) {
        const [int, dec] = (distance / 1000).toFixed(2).split('.')
        return `${ isRecommend ? '< ' : '' }${ Number(int).toLocaleString() }.${ dec }km`
      }
      return `${ isRecommend ? '< ' : '' }${ distance }m`
    }
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable */
.j-goods-detail-address-upper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transition: all .5s ease-in-out;
    z-index: 201;
    &.mshe-z-bottom-transform2.upper-bottom-transform2 {
        transform: translate3d(0,130%,999px);
    }
    .c-upper-wrap {
        .common-address-header {
            position: absolute;
            top: 0;
            width: 100%;
            background: #fff;
            z-index: 102;
            .address-header-inner {
                .flexbox;
                .align-center;
                height: 1.2rem;
                .header-title {
                    text-align: center;
                    width: 100%;
                    .font-dpr(34px);
                    font-weight: bold;
                    color: #333333;
                }
                .header-close {
                    position: absolute;
                    float: left;
                    padding: .2rem .4rem;
                    .icon-close {
                        .font-dpr(32px);
                    }
                }
            }
            .address-header-tab {
                .flexbox;
                .align-center;
                height: 1.17333rem;
                white-space: nowrap;
                overflow-x: scroll;
                width: 100%;
                padding: 0.3467rem .32rem .3733rem/*rtl:ignore*/;
                border-bottom: 1px solid #e5e5e5/*rtl:ignore*/;
                &::-webkit-scrollbar {
                    width: 0px;
                    height: 0px;
                }
                .j-tab-item {
                    color: #666666;
                    .font-dpr(28px);
                    height: 1.16666rem;
                    line-height: 1.16666rem;
                    font-weight: bold;
                    .margin-r(.53333rem);
                    display: inline-block;
                    &.selected {
                        color: #222;
                        border-bottom: 2px solid #222;
                    }
                }
            }
            .address-header-search {
                position: relative;
                height: 1.49333rem;
                padding: 0 .32rem;
                .flexbox;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid #e5e5e5;
                .padding-r(0.6rem);
                .search-input {
                    position: relative;
                    flex: 1;
                    .icon-search01 {
                        position: absolute;
                        .left(.26666rem);
                        top: .42333rem;
                        .font-dpr(32px);
                        z-index: 1000;
                    }
                    .icon-clear1 {
                        position: absolute;
                        .right(.26666rem);
                        top: .42333rem;
                        .font-dpr(32px);
                        z-index: 1000;
                        color: #ccc;
                    }
                    input {
                        width: 100%;
                        height: .853333rem;
                        margin: .32rem 0;
                        border: none;
                        background: #f6f6f6;
                        color: #222;
                        padding: 0 .90666rem;
                        box-sizing: border-box;
                        &::-webkit-input-placeholder {      /* WebKit browsers*/
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &::-moz-placeholder {            /* Mozilla Firefox 19+ */
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &:-moz-placeholder {              /* Mozilla Firefox 4 to 18 */
                            color: #999!important;
                            opacity: 1!important;
                        }
                        &:-ms-input-placeholder {              /* Internet Explorer 10+*/
                            color: #999!important;
                            opacity: 1!important;
                        }
                    }
                }
                .search-cancel {
                    .font-dpr(28px);
                    color: #222;
                    text-transform : capitalize;
                    .margin-l(.32rem);
                    padding-top: .48rem;
                }
            }
            .address-header-login-tip{
                height: 1.08rem;
                background-color: #FCF0D4;
                line-height: 1.08rem;
                padding: 0 .32rem;
                position: relative;
                .flexbox();
                .align-center();
                .space-between();
                .login-tip-content{
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    .close-wrap{
                        display: inline-block;
                        width: 1rem;
                        position: absolute;
                        .left(0);
                        .icon-close{
                            .font-dpr(24px);
                            color: #999999;
                            .padding-l(.32rem);
                        }
                    }
                    span{
                        .font-dpr(24px);
                        color: #222;
                        .padding-l(.64rem);
                    }
                }

                button{
                    height: .53333rem;
                    .font-dpr(22px);
                    color: #222;
                    border: 1px solid #333333;
                    background-color: #fcf0d4;
                    padding: 0 .2133rem;
                }
            }
        }
        .c-upper-list {
            padding: 2.34666rem 0 1rem;
            height: 100%;
            overflow-y: scroll;
            box-sizing: border-box;
            &.has-search {
                padding: 3.86rem 0 1rem;
            }
            &.has-login {
                padding: 3.45rem 0 1rem;
            }
            &.has-search-login {
                padding: 4.9rem 0 1rem;
            }
            .empty-tips {
                text-align: center;
            }
            .addr-upper-item {
                .group-title {
                    .font-dpr(28px);
                    background: #F6F6F6;
                    padding: .03rem .32rem;
                    font-weight: bold;
                    &.country-group {
                        background: #fff;
                        padding-top: .32rem;
                    }
                }
                .upper-list {
                    padding: 0 .32rem  .32rem;
                    li {
                        position: relative;
                        .font-dpr(28px);
                        padding: .32rem 0;
                        border-bottom: 1px solid #e5e5e5;
                        color: #333;
                        .icon-normal-checked {
                            position: absolute;
                            .font-dpr(36px);
                            .right(0.32rem);
                            .left(auto);
                            font-weight: normal;
                            top: .2rem;
                            color: @color_brand;
                        }
                        &.disabled {
                            color: #ccc;
                        }
                        &.selected {
                            font-weight: bold;
                        }
                        .result-content {
                          .title {
                            margin-bottom: 0;
                          }
                          .desc {
                            margin: .213rem 0 0;
                          }
                          .logistics {
                            display: flex;
                            align-items: stretch;
                            flex-wrap: wrap;
                            margin-top: .319rem;
                            .logo-panel {
                              display: inline-flex;
                              align-items: center;
                              .margin-r(.213rem);
                            }
                            .logo {
                              width: .48rem;
                              height: auto;
                            }
                            .type {
                              padding: .053rem .106rem;
                              color: #666;
                              .font-dpr(24px);
                              word-break: break-word;
                              background: #EFF3F8;
                            }
                            .recommend {
                              display: flex;
                              align-items: center;
                              .margin-r(.213rem);
                              padding: .1rem .126rem;
                              background: @sui_color_safety_bg;
                              color: @sui_color_safety;
                              .font-dpr(24px);
                              line-height: 1;
                              font-weight: bold;
                              .txt {
                                .margin-l(.126rem);
                              }
                            }
                          }
                          .open-time {
                            margin-top: .213rem;
                          }
                          .tel {
                            margin-top: .213rem;
                          }
                        }
                    }
                }
                &.addr-area-item {
                    .upper-list {
                        li {
                            padding: .32rem 0;
                            .margin-r(.18rem);
                            .icon-normal-checked {
                                position: absolute;
                                .font-dpr(36px);
                                .right(0.32rem);
                                font-weight: normal;
                                top: .2rem;
                            }
                        }
                    }
                }
            }
        }
        .c-upper-silder {
            position: absolute;
            top: 50%;
            .right(0);
            z-index: 2999;
            transform: translate3d(0,-50%,2999px);
            overflow-y: hidden;
            text-align: center;
            font-size: 11px;
            background-color: #fff;
            .addr-shortcut-list {
                li {
                    .padding-l(.08rem);
                    .padding-r(.08rem);
                    color: #9b9b9b;
                    .shortcut-item {
                        display: inline-block;
                        height: .42666rem;
                        width: .42666rem;
                        line-height: .42666rem;
                        .font-dpr(26px);
                        &.selected {
                            background: #222;
                            border-radius: 50%;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .shortcut-selected {
            position: fixed;
            .font-dpr(40px);
            color: #fff;
            .right(.53333rem);
            width: .90666rem;
            height: .64rem;
            line-height: .64rem;
            .name {
                position: relative;
                .padding-l(.16rem);
            }
            img {
                position: absolute;
                width: 100%;
            }
        }
    }
    &.c-address-upper-store {
		.c-upper-wrap {
			background: #F6F6F6;
			.common-address-header {
				.address-header-search {
					.padding-r(.32rem);
					border: none;
					.border-dpr(border-top, 2px, #e5e5e5)
				}
				.address-header-tab {
					position: relative;
					padding: 0;
					border: none;
					.border-dpr(border-bottom, .64rem, #F6F6F6);
					overflow: hidden;
					.j-tab-item {
						height: 1.167rem;
						line-height: 1.167rem;
						&.selected {
							color: @sui_color_highlight;
							border: none; 
						}
					}
					.tab-wrapper {
						padding: 0 .32rem;
						width: 100%;
						height: 1.1667rem;
						overflow-x: scroll;
						overflow-y: hidden;
					}
					// &:after,
					// &:before {
					// 	content: '';
					// 	position: absolute;
					// 	top: 0;
					// 	.right(0);
					// 	width: .32rem;
					// 	height: 100%;
					// 	background-image: linear-gradient(to right, transparent 0%, #fff 100%);
					// }
					// &:before {
					// 	.right(auto);
					// 	.left(0);
					// 	background-image: linear-gradient(to left, transparent 0%, #fff 100%);
					// }
				}
			}
			.c-upper-list {
				.addr-upper-item {
					background: #fff;
					.upper-list {
						.padding-r(0);
						padding-bottom: 0;
					}
					&.addr-area-item {
						.upper-list li {
							.margin-r(0);
							.icon-normal-checked {
								position: static;
							}
						}
					}
					&:last-child {
						.upper-list li:last-child{
							border: none;
						}
					}
				}
			}
			.c-upper-silder {
				background-color: transparent;
			}
		}
		&.ru {
			position: static;
			.c-upper-wrap {
				.common-address-header {
					.address-header-tab {
						height: auto;
					}
					.address-header-inner {
						border-bottom: 1px solid #e5e5e5;
						.header-title {
							color: #767676;
							.font-dpr(32px);
							font-weight: normal;
						}
						.header-close {
							position: static;
							align-self: flex-start;
							padding: .1rem 0 0 0;
							.padding-r(.1rem);
							.icon-close {
								color: #999;
								.font-dpr(20px);
							}
						}
					}
					.address-header-search {
						border: none;
					}
				}
				.c-upper-list {
					padding-bottom: 1.48rem;
					background: #fff;
					.upper-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						[class*="iconfont"] {
							.padding-r(.32rem);
						}
					}
					.result-item {
						display: flex;
            align-items: flex-start;
					}
					.result-content {
						flex: 1;
						.padding-l(.26666rem);
						.title {
							.font-dpr(32px);
							color: #222;
							width: 8.5rem;
							margin-bottom: .08rem;
              .distance {
                .margin-l(.267rem);
                .font-dpr(24px);
                white-space: nowrap;
              }
						}
						.desc {
							.font-dpr(28px);
							color: #666;
							margin-bottom: .21333rem;
						}
						.open-time {
							.font-dpr(24px);
						}
						.child {
							.font-dpr(24px);
							color: #999;
							.padding-l(.57rem);
							.time-list {
								>li {
									.flexbox;
									padding: .08rem 0;
                  color: @sui_color_gray_light1;
									.font-dpr(24px);
                  border: none;
									.date, .time {
										flex: 1;
									}
								}
							}
						}
            .flex {
              display: flex;
              align-items: center;
              .suiiconfont {
                align-self: flex-start;
                color: #222;
                color: #222;
                .font-dpr(30px);
              }
              .info {
                flex: 1;
                .margin-l(.16rem);
								color: @sui_color_gray_dark2;
								.font-dpr(28px);
              }
              &.title {
                justify-content: space-between;
              }
            }
					}
					.oops {
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 1.4rem .32rem;
						.icon-Rectangle {
							.font-dpr(150px);
						}
						.desc {
							margin-top: .53rem;
							color: #999;
							text-align: center;
						}
					}
				}
				.bottom {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: .16rem .32rem;
					background: #fff;
				}
			}
		}
	}
}
/* stylelint-enable */
</style>
